:root {
    --primary-color: #455A64;
    --primary-color-alpha: #E0F8FF;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  .app__wrapper {
    display: flex;
    flex: 1;
    height: 90%;
    margin-bottom: 16px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.33) 0px 1px 4px 0px;
  }

  .str-chat-channel-list {
    margin-bottom: 8px;
  }

  .str-chat-channel {
    height: 100%;
  }

  .str-chat__load-more-button {
    display: none;
  }

  .str-chat__input-footer {
    display: none;
  }

  .str-chat__date-separator {
    margin: 16px 24px;
  }

  .str-chat__message-notification {
    background: var(--primary-color);
    cursor: pointer;
  }

  @media screen and (max-width: 960px) {
    .str-chat-channel-list.team {
      position: unset;
      left: unset;
      top: unset;
      z-index: unset;
      min-height: unset;
      overflow-y: unset;
      box-shadow: unset;
      transition: unset;
    }
  }