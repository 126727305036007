.channel-list__container {
  display: none; /* flex */
  height: 100%;
  box-shadow: inset 1px 0px 0px rgba(0, 0, 0, 0.1);
}

.channel-list__sidebar {
  width: 72px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    var(--primary-color);
  box-shadow: 1px 0px 0px rgba(0, 0, 0, 0.25);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: none;
}

.channel-list__sidebar__icon1 {
  width: 44px;
  height: 44px;
  margin: 14px;
  background: linear-gradient(
      150.64deg,
      rgba(0, 0, 0, 0.1) 12.73%,
      rgba(0, 0, 0, 0) 89.32%
    ),
    #ffffff;
  border-radius: 9999px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.33);
}

.channel-list__list__wrapper {
  display: flex;
  flex-direction: column;
  background: var(--primary-color);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 240px;
}

.channel-list__group {
  overflow-y: scroll;
}

.channel-list__group::-webkit-scrollbar {
  background-color: transparent;
  width: 0px;
}

.channel-list__group:hover::-webkit-scrollbar {
  width: 8px;
}

.channel-list__group::-webkit-scrollbar-thumb {
  background-color: rgb(93, 121, 135);
}

@media (hover: none) {
  .channel-list__group::-webkit-scrollbar {
    width: 8px;
  }
  .channel-list__group::-webkit-scrollbar-thumb {
    background-color: rgb(93, 121, 135);
  }
}

.icon1__inner {
  font-family: Helvetica Neue, sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channel-list__sidebar__icon2 {
  width: 44px;
  height: 44px;
  margin-left: 14px;
  background: linear-gradient(0deg, #000000, #000000);
  opacity: 50%;
  border-radius: 9999px;
}

.icon2__inner {
  font-family: Helvetica Neue, sans-serif;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channel-list__header {
  min-height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
}

.channel-list__header__text {
  font-family: Helvetica Neue, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px;
  min-width: 55%;
}

.team.str-chat-channel-list {
  overflow-x: hidden;
  margin-bottom: 0;
  overflow-y: hidden;
  height: min-content;
  width: 100%;
}

.add-channel {
  z-index: 2;
}
