.str-chat {
  --str-chat__message-textarea-background-color: white;
  --str-chat__message-textarea-border-radius: 8px;
  --str-chat__suggestion-list-container-background-color: white;
  --str-chat__message-send-color: var(--primary-color);
}

textarea {
  background: var(--static-background-color);
  font-size: 15px;
  border: none;
  outline: none;
}

textarea::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.str-chat__dropzone-container {
  background-color: var(--str-chat__dropzone-container-background-color);
  color: var(--str-chat__dropzone-container-color);
  backdrop-filter: var(--str-chat__dropzone-container-backdrop-filter);
  font: var(--str-chat__subtitle-text);
}

.str-chat__suggestion-list-container {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.str-chat__send-button {
  background: var(--str-chat__message-send-background-color);
  color: var(--str-chat__message-send-color);
  box-shadow: var(--str-chat__message-send-box-shadow);
  border-radius: 8px;
  border-block-start: var(--str-chat__message-send-border-block-start);
  border-block-end: var(--str-chat__message-send-border-block-end);
  border-inline-start: var(--str-chat__message-send-border-inline-start);
  border-inline-end: var(--str-chat__message-send-border-inline-end);
  cursor: pointer;
}

.str-chat__send-button svg path {
  fill: var(--str-chat__message-send-color);
}

.str-chat__send-button:disabled {
  background-color: var(--str-chat__message-send-disabled-background-color);
}

.str-chat__send-button:disabled svg path {
  fill: var(--str-chat__message-send-disabled-color);
}

.message-input__controls-button {
  border-radius: 5px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.message-input__controls-button path {
  fill-opacity: 0.2;
  fill: #000;
}

.message-input__controls-button.active path {
  fill-opacity: 1;
  fill: var(--primary-color);
}

.message-input__controls-button:hover path {
  fill: var(--primary-color);
  fill-opacity: 1;
}

.giphy-icon__wrapper {
  background: var(--primary-color);
  border-radius: 12px;
}

.giphy-icon__wrapper .giphy-icon__text {
  font-weight: var(--font-weight-bold);
  font-size: 11px;
  color: white;
}

.emoji-mart-anchor-selected {
  color: var(--primary-color) !important;
}

.emoji-mart-anchor-bar {
  background: var(--primary-color) !important;
}

.emoji-mart .emoji-mart-emoji:focus {
  outline: none;
}

div.rfu-dropzone:focus {
  outline: none;
}

.str-chat__edit-message-form .str-chat__message-input {
  padding-inline: 0;
}

.str-chat__edit-message-form .str-chat__file-input-container,
.str-chat__emoji-picker-button {
  display: none;
}

.str-chat__edit-message-form .str-chat__message-textarea-with-emoji-picker {
  padding: 0;
}

.str-chat__edit-message-form .str-chat__message-textarea-container {
  overflow: hidden;
}

.str-chat__edit-message-form .str-chat__message-textarea-react-host {
  position: static;
}

.str-chat__edit-message-form .str-chat__message-textarea-react-host .str-chat__suggestion-list-container {
  width: 100%;
  left: 0;
  bottom: 85%;
}

.str-chat__edit-message-form .str-chat__message-textarea-container {
  border: 1px solid var(--primary-color);
}

.str-chat__edit-message-form .str-chat__message-textarea-container:focus-within {
  border-width: 2px;
}

.str-chat__edit-message-form .str-chat__edit-message-cancel {
  color: rgba(0,0,0,.33);
}

.str-chat__edit-message-form .str-chat__edit-message-cancel,
.str-chat__edit-message-send {
  font-weight: var(--font-weight-bold);
  text-transform: none;
}

textarea {
  width: 100%;
  resize: none;
  padding: 16px 10px;
}

.str-chat__textarea {
  height: auto;
  flex: 1 1;
}

.message-input__controls-button {
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.message-input__controls-button svg {
  width: 30px;
}

.str-chat__dropzone-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 5;
}

.giphy-icon__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 24px;
  width: 63px;
  margin-left: 8px;
}

.rfu-image-previewer {
  flex: none;
  margin-left: 12px;
}

.rfu-image-previewer__image {
  margin-bottom: 0;
}

.team-message-input__wrapper {
  position: relative;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.team-message-input__wrapper .team-message-input__input .team-message-input__top {
  min-height: 43px;
  width: 100%;
}

.team-message-input__wrapper .team-message-input__input .team-message-input__top .team-message-input__form {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.team-message-input__wrapper .team-message-input__input .team-message-input__top .team-message-input__form .str-chat__send-button {
  display: flex;
  align-items: center;
  padding: 10px;
}

.team-message-input__wrapper .team-message-input__input .team-message-input__top .team-message-input__form .str-chat__suggestion-list-container {
  width: unset;
  bottom: 85%;
  right: 20px;
  left: 20px;
}

.team-message-input__wrapper .team-message-input__input .team-message-input__top .team-message-input__form .str-chat__send-button svg {
  width: calc(var(--str-chat__spacing-px) * 20);
  height: calc(var(--str-chat__spacing-px) * 20);
}

.team-message-input__wrapper .team-message-input__input .team-message-input__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
}

.team-message-input__wrapper .str-chat__input--emojipicker {
  z-index: 3;
  position: absolute;
  bottom: 112px;
  left: 20px;
  width: 338px;
}

.team-message-input__wrapper {
  background: var(--panel-primary-background-color);
}

.team-message-input__wrapper .team-message-input__input .team-message-input__top {
  background: white;
}

.team-message-input__wrapper .team-message-input__input .team-message-input__bottom {
  background: rgba(0, 0, 0, 0.05);
}

.rfu-file-upload-button {
  padding: 12px;
  cursor: pointer;
}

.rfu-file-input {
 display: none;
}

.rfu-file-upload-button.active path {
  fill-opacity: 1;
  fill: var(--primary-color);
}

.rfu-file-upload-button:hover path {
  fill: var(--primary-color);
  fill-opacity: 1;
}

.rfu-file-upload-button div {
  cursor: pointer;
}

.rfu-file-upload-button label {
  cursor: pointer;
}
